body
{
  background-image:
    linear-gradient( rgba(0,0,0,.8), rgba(0,0,0,.8) ), url("/static/photo-1522673999312-93aa76caeda6.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
ul.share-buttons{
  list-style: none;
  padding: 0;
}

ul.share-buttons li{
  display: inline;
  padding: 5px;
}

ul.share-buttons .sr-only{
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
html > body > div.root  
{
  height: 100vh;
}